import dayjs, { Dayjs } from 'dayjs';

import timezoneManagement from 'technical/time-utils/dayjs.timezone';

function parseShiftTimeString(timeString: string) {
  return dayjs.tz(timeString, 'HH:mm:ss', timezoneManagement.getDefaultTz());
}

export function parseShiftTimeStringWithReportDate(
  shiftTimeString: string,
  reportDate: Dayjs,
) {
  const shiftTimeNotOnDate = parseShiftTimeString(shiftTimeString);
  const shiftHour = shiftTimeNotOnDate.hour();
  const shiftMinute = shiftTimeNotOnDate.minute();

  return reportDate.hour(shiftHour).minute(shiftMinute);
}
