import { SwapRightOutlined } from '@ant-design/icons';
import { InputNumber, Space } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

export type NumberRangeValue =
  | [number | null | undefined, number | null | undefined]
  | null;

export interface NumberRangeInputProps {
  values: NumberRangeValue;
  onChange: (values: NumberRangeValue) => void;
  leftInputProps?: InputNumberProps;
  rightInputProps?: InputNumberProps;
}

function NumberRangeInput({
  values,
  onChange,
  leftInputProps,
  rightInputProps,
}: Readonly<NumberRangeInputProps>) {
  return (
    <Space>
      <InputNumber
        {...leftInputProps}
        value={values?.[0] ? values[0] : undefined}
        onChange={(value) =>
          typeof value !== 'string'
            ? onChange([value, values ? values[1] : undefined])
            : onChange([undefined, values ? values[1] : undefined])
        }
      />
      <SwapRightOutlined />
      <InputNumber
        {...rightInputProps}
        value={values?.[1] ? values[1] : undefined}
        onChange={(value) =>
          typeof value !== 'string'
            ? onChange([values ? values[0] : undefined, value])
            : onChange([values ? values[0] : undefined, undefined])
        }
      />
    </Space>
  );
}

export default NumberRangeInput;
