import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import {
  setCurrentAllowedRoles,
  setDefaultCurrentAllowedRoles,
} from 'business/user/services/authentication';
import { DEFAULT_ALLOWED_ROLES, ROLES } from 'business/user/services/config';
import fetchConstructionSiteList from 'business/user/services/constructionSite';
import {
  CommonUserFieldsFragment,
  ConstructionSiteFieldsFragment,
} from 'generated/graphql';
import usePersistedStringState from 'technical/persist';
import timezoneManagement from 'technical/time-utils/dayjs.timezone';

function getConstructionSiteListOfAllowedRolesForUser(
  constructionSiteList: ConstructionSiteFieldsFragment[],
  constructionSiteId?: string,
  user?: CommonUserFieldsFragment,
): ROLES[] {
  const currentConstructionSite =
    constructionSiteList.find(
      (constructionSite) => constructionSite.id === constructionSiteId,
    ) ?? null;
  if (!user || !currentConstructionSite) {
    return DEFAULT_ALLOWED_ROLES;
  }
  return currentConstructionSite.userPermissions.reduce((acc, permission) => {
    if (user?.id === permission.userId) {
      return [...acc, permission.permissionType.name as ROLES];
    }
    return acc;
  }, [] as ROLES[]);
}

function useConstructionSiteData(user?: CommonUserFieldsFragment) {
  const [isBootstraped, setIsBootstraped] = useState(false);
  const [constructionSiteList, setConstructionSiteList] = useState<
    ConstructionSiteFieldsFragment[]
  >([]);
  const [currentConstructionSiteId, setCurrentConstructionSiteId] =
    usePersistedStringState('constructionSite');

  const client = useApolloClient();

  const fetchAndStoreConstructionSiteList = useCallback(
    async function refetchConstructionSiteList() {
      const list = await fetchConstructionSiteList(client);
      if (list && list.length > 0) {
        setConstructionSiteList(list);
        const currentConstructionSite = list.find(
          (constructionSite) =>
            constructionSite.id === currentConstructionSiteId,
        );
        if (!currentConstructionSite) {
          setCurrentConstructionSiteId(list[0].id);
          timezoneManagement.setDefaultTz(list[0].timeZone);
          setCurrentAllowedRoles(
            getConstructionSiteListOfAllowedRolesForUser(
              list,
              list[0].id,
              user,
            ),
          );
        } else {
          timezoneManagement.setDefaultTz(currentConstructionSite.timeZone);
          setCurrentAllowedRoles(
            getConstructionSiteListOfAllowedRolesForUser(
              list,
              currentConstructionSiteId,
              user,
            ),
          );
        }
      } else {
        setCurrentConstructionSiteId(undefined);
        setDefaultCurrentAllowedRoles();
      }
      setIsBootstraped(true);
    },
    [client, currentConstructionSiteId, setCurrentConstructionSiteId, user],
  );

  const updateCurrentConstructionSiteIdAndRole = (
    constructionSiteId: string,
  ) => {
    const currentConstructionSite =
      constructionSiteList.find(
        (constructionSite) => constructionSite.id === constructionSiteId,
      ) || null;
    if (!currentConstructionSite) {
      throw new Error('No construction site in list for construction site id');
    }

    timezoneManagement.setDefaultTz(currentConstructionSite.timeZone);
    setCurrentConstructionSiteId(constructionSiteId);
    setCurrentAllowedRoles(
      getConstructionSiteListOfAllowedRolesForUser(
        constructionSiteList,
        constructionSiteId,
        user,
      ),
    );
  };

  useEffect(() => {
    if (user) {
      fetchAndStoreConstructionSiteList();
    }
  }, [client, fetchAndStoreConstructionSiteList, user]);

  return {
    isBootstraped,
    currentConstructionSiteId,
    currentConstructionSite:
      constructionSiteList.find(
        (constructionSite) => constructionSite.id === currentConstructionSiteId,
      ) || null,
    setCurrentConstructionSiteId: updateCurrentConstructionSiteIdAndRole,
    constructionSites: constructionSiteList,
    refreshConstructionSiteList: fetchAndStoreConstructionSiteList,
  };
}

export default useConstructionSiteData;
