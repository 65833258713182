import { message } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { Dayjs } from 'dayjs';
import { i18n } from 'translations';

export const requiredRule = {
  required: true,
  message: i18n.t('errors.required'),
};

interface CustomRule {
  type?:
    | 'string'
    | 'number'
    | 'boolean'
    | 'method'
    | 'regexp'
    | 'integer'
    | 'float'
    | 'object'
    | 'enum'
    | 'date'
    | 'url'
    | 'hex'
    | 'email';
  // Any here because i18n does not return a string
  [key: string]: any;
}

export const positiveNumberRule: CustomRule = {
  type: 'number',
  min: 0,
  message: i18n.t('errors.too_small_number', { number: 0 }),
};

export const limitedInputLength: CustomRule = {
  type: 'string',
  max: 50,
  message: i18n.t('errors.too_long_number', { number: 50 }),
};

export const customLimitedInputLength = (lenghtMax: number): CustomRule => ({
  type: 'string',
  max: lenghtMax,
  message: i18n.t('errors.too_long_number', { number: lenghtMax }),
});

export const negativeProgressionCheck = (
  startMetricPoint: number,
): CustomRule => ({
  validator(_rule: RuleObject, value: number) {
    if (value < startMetricPoint) {
      return Promise.reject(i18n.t('errors.negative_progression'));
    }
    return Promise.resolve();
  },
});

export const limitedTextAreaLength: CustomRule = {
  type: 'string',
  max: 500,
  message: i18n.t('errors.too_long_number', { number: 500 }),
};

export const urlValidation: CustomRule = {
  pattern:
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/,
  message: i18n.t('errors.wrong_url_format'),
};

export const notBeforeStartDate =
  (name: NamePath) =>
  ({ getFieldValue }: { getFieldValue: (name: NamePath) => any }) => ({
    validator(_rule: RuleObject, value: Dayjs) {
      const startDate: Dayjs = getFieldValue(name);
      if (!value || !startDate) {
        return Promise.resolve();
      }
      if (
        value.isBefore(startDate) &&
        startDate.diff(value, 'hours', true) > 0 &&
        startDate.diff(value, 'hours', true) < 12
      ) {
        return Promise.reject(i18n.t('errors.before_start'));
      }
      return Promise.resolve();
    },
  });

export const notAfterEndDate =
  (name: NamePath) =>
  ({ getFieldValue }: { getFieldValue: (name: NamePath) => any }) => ({
    validator(_rule: RuleObject, value: Dayjs) {
      const endDate: Dayjs = getFieldValue(name);
      if (!value || !endDate) {
        return Promise.resolve();
      }
      if (
        value.isAfter(endDate) &&
        value.diff(endDate, 'hours', true) < 12 &&
        value.diff(endDate, 'hours', true) > 0
      ) {
        return Promise.reject(i18n.t('errors.after_end'));
      }
      return Promise.resolve();
    },
  });

export const requiredErrorAlert = () => {
  message.error(i18n.t('errors.missing_required_fields'));
};
