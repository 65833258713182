import { ApolloClient } from '@apollo/client';

import { ConstructionSiteListQuery } from 'generated/graphql';

import { QUERY_CONSTRUCTION_SITE_LIST } from './query.gql';

export default async function fetchConstructionSiteList(
  client: ApolloClient<object>,
) {
  const { data } = await client.query<ConstructionSiteListQuery>({
    query: QUERY_CONSTRUCTION_SITE_LIST,
    fetchPolicy: 'network-only',
  });
  if (data) {
    const { constructionSiteList } = data;
    return constructionSiteList;
  }
  return undefined;
}
