import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppContext } from 'business/provider';
import Routes from 'config/routes';

import ConnectedPage from './layout/connectedPage';
import ShiftConnectedPage from './layout/shiftConnectedPage';

const DEFAULT_NOT_CONNECTED_ROUTE = Routes.App;
const DEFAULT_CONNECTED_ROUTE = Routes.Home;
/**
 * Redirect the user to the default not-connected route when the user
 * is not-connected and intend to access connected route
 */
export const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { isConnected } = useAppContext();
  if (!isConnected) {
    return <Navigate to={DEFAULT_NOT_CONNECTED_ROUTE} />;
  }

  return <ConnectedPage>{children}</ConnectedPage>;
};

/**
 * Redirect the user to the default connected route when the user is
 * connected and intend to access not connected route
 */
export const AnonymousRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { isConnected } = useAppContext();

  if (isConnected) {
    return <Navigate to={DEFAULT_CONNECTED_ROUTE} />;
  }

  return <>{children}</>;
};

export const ShiftProtectedRoute: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isConnected } = useAppContext();
  if (!isConnected) {
    return <Navigate to={DEFAULT_NOT_CONNECTED_ROUTE} />;
  }

  return <ShiftConnectedPage>{children}</ShiftConnectedPage>;
};
