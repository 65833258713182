import { RouteObject } from 'react-router-dom';

import Indicators from 'business/indicators/pages/Indicators';
import {
  AnonymousRoute,
  ProtectedRoute,
  ShiftProtectedRoute,
} from 'business/protectedRouteContainer';
import ReportAggregatedPage from 'business/report/pages/ReportAggregated';
import ReportCreatePage from 'business/report/pages/ReportCreate';
import ReportEditPage from 'business/report/pages/ReportEdit';
import ReportEditEndPage from 'business/report/pages/ReportEditEnd';
import ReportEditFinishingPage from 'business/report/pages/ReportEditFinishing';
import ReportExportAggregatedPage from 'business/report/pages/ReportExportAggregated';
import ReportExportBatchPage from 'business/report/pages/ReportExportBatch';
import ReportListPage from 'business/report/pages/ReportList';
import ReportListInProgressPage from 'business/report/pages/ReportListInProgress';
import ReportListToValidatePage from 'business/report/pages/ReportListToValidate';
import ReportListValidatedPage from 'business/report/pages/ReportListValidated';
import ReportViewPage from 'business/report/pages/ReportView';
import TaskExportPage from 'business/task/pages/TaskExport';
import TasksAnalyzer from 'business/task/pages/TasksAnalyzer';
import DashboardPage from 'business/user/pages/Dashboard';
import Home from 'business/user/pages/home';
import LandingPage from 'business/user/pages/landing';
import LoginProviderCallbackScreen from 'business/user/pages/loginProviderCallback';
import NoConstructionSite from 'business/user/pages/noConstructionSite';
import UnauthorizedPage from 'business/user/pages/unauthorized';
import Routes from 'config/routes';
import ReportingNoMatch from 'technical/router/switch/reporting-no-match';

export const commonRoutesProps: RouteObject[] = [
  {
    path: Routes.App,
    element: (
      <AnonymousRoute>
        <LandingPage />
      </AnonymousRoute>
    ),
  },
  {
    path: Routes.LoginCallback,
    element: (
      <AnonymousRoute>
        <LoginProviderCallbackScreen />
      </AnonymousRoute>
    ),
  },

  {
    path: '*',
    element: (
      <ProtectedRoute>
        <ReportingNoMatch />
      </ProtectedRoute>
    ),
  },
];

export const unauthorizedRoutesProps: RouteObject[] = [
  {
    path: Routes.Home,
    element: (
      <ProtectedRoute>
        <UnauthorizedPage />
      </ProtectedRoute>
    ),
  },
];

export const noConstructionSite = [
  {
    path: Routes.Home,
    element: (
      <ProtectedRoute>
        <NoConstructionSite />
      </ProtectedRoute>
    ),
  },
];

export const userRoutesProps: RouteObject[] = [
  {
    path: Routes.Home,
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
  },

  // Report list / history
  {
    path: Routes.ReportList,
    element: (
      <ProtectedRoute>
        <ReportListPage />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ReportHistory,
  //   redirectTo: Routes.ReportList,
  // },

  // Report edit / report chart
  {
    path: Routes.ReportEdit,
    element: (
      <ShiftProtectedRoute>
        <ReportEditPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ReportChart,
  //   redirectTo: Routes.ReportEdit,
  // },

  // Report edit finishing / report chart finishing
  {
    path: Routes.ReportEditFinishing,
    element: (
      <ShiftProtectedRoute>
        <ReportEditFinishingPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ReportEndReportForm,
  //   redirectTo: Routes.ReportEditFinishing,
  // },

  // Report edit end / report chart end
  {
    path: Routes.ReportEditEnd,
    element: (
      <ShiftProtectedRoute>
        <ReportEditEndPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ReportEndReportResult,
  //   redirectTo: Routes.ReportEditEnd,
  // },

  // Report View / Manager Print report
  {
    path: Routes.ReportView,
    element: (
      <ShiftProtectedRoute>
        <ReportViewPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerReportPrint,
  //   redirectTo: Routes.ReportView,
  // },

  // Aggregated report / Manager Aggregated report - for backend puppeteer
  {
    path: Routes.ReportAggregated,
    element: (
      <ShiftProtectedRoute>
        <ReportAggregatedPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerAggregatedReportPrint,
  //   redirectTo: Routes.ReportAggregated,
  // },
];

export const constructionSiteManagerReadRoutesProps: RouteObject[] = [
  {
    path: Routes.Home,
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
  },

  // Report list to validate / Manager validation list
  {
    path: Routes.ReportListToValidate,
    element: (
      <ProtectedRoute>
        <ReportListToValidatePage />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerReportValidationList,
  //   redirectTo: Routes.ReportListToValidate,
  // },

  // Report list in progress / Manager pending report
  {
    path: Routes.ReportListInProgress,
    element: (
      <ProtectedRoute>
        <ReportListInProgressPage />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerPendingReport,
  //   redirectTo: Routes.ReportListInProgress,
  // },

  // Report list validated / manager history
  {
    path: Routes.ReportListValidated,
    element: (
      <ProtectedRoute>
        <ReportListValidatedPage />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerReportHistoryList,
  //   redirectTo: Routes.ReportListValidated,
  // },

  // Report edit / report chart
  // Available in read routes for full reader
  // Inside components permission check prevent reader to edit
  {
    path: Routes.ReportEdit,
    element: (
      <ShiftProtectedRoute>
        <ReportEditPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ReportChart,
  //   redirectTo: Routes.ReportEdit,
  // },

  // Task analyzer / tasks-analyzer
  {
    path: Routes.TaskAnalyzer,
    element: (
      <ProtectedRoute>
        <TasksAnalyzer />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: Routes.TaskAnalyzerOld,
  //   redirectTo: Routes.TaskAnalyzer,
  // },

  // Indicators analysis / indicators
  {
    path: Routes.Indicators,
    element: (
      <ProtectedRoute>
        <Indicators />
      </ProtectedRoute>
    ),
  },

  // Report view / Manager pdf report
  {
    path: Routes.ReportView,
    element: (
      <ShiftProtectedRoute>
        <ReportViewPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerReportPrint,
  //   redirectTo: Routes.ReportView,
  // },

  // Report aggregated / Manager pdf aggregated
  {
    path: Routes.ReportAggregated,
    element: (
      <ShiftProtectedRoute>
        <ReportAggregatedPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerAggregatedReportPrint,
  //   redirectTo: Routes.ReportAggregated,
  // },

  // Report export aggregated / Manager aggregated-report
  {
    path: Routes.ReportExportAggregated,
    element: (
      <ShiftProtectedRoute>
        <ReportExportAggregatedPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerAggregatedReport,
  //   redirectTo: Routes.ReportExportAggregated,
  // },

  // Report export batch / Manager batch report
  {
    path: Routes.ReportExportBatch,
    element: (
      <ShiftProtectedRoute>
        <ReportExportBatchPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.ManagerBatchReport,
  //   redirectTo: Routes.ReportExportBatch,
  // },

  // Task Export / Manager task-export
  {
    path: Routes.TaskExport,
    element: (
      <ShiftProtectedRoute>
        <TaskExportPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  //   path: Routes.TaskExportOld,
  //   redirectTo: Routes.TaskExport,
  // },
];

export const constructionSiteManagerEditRoutesProps: RouteObject[] = [
  // Report create / manager create-report
  {
    path: Routes.ReportCreate,
    element: (
      <ProtectedRoute>
        <ReportCreatePage />
      </ProtectedRoute>
    ),
  },
  // {
  // path: Routes.ManagerNewReport,
  // redirectTo: Routes.ReportCreate,
  // },

  // Report list / report history
  {
    path: Routes.ReportList,
    element: (
      <ProtectedRoute>
        <ReportListPage />
      </ProtectedRoute>
    ),
  },
  // {
  // path: Routes.ReportHistory,
  // redirectTo: Routes.ReportList,
  // },

  // Report edit finishing / report chart finishing
  {
    path: Routes.ReportEditFinishing,
    element: (
      <ShiftProtectedRoute>
        <ReportEditFinishingPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.ReportEndReportForm,
  // redirectTo: Routes.ReportEditFinishing,
  // },

  // Report edit end / report chart end &  manager chart end
  {
    path: Routes.ReportEditEnd,
    element: (
      <ShiftProtectedRoute>
        <ReportEditEndPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.ReportEndReportResult,
  // redirectTo: Routes.ReportEditEnd,
  // },
  // {
  // path: Routes.ManagerReportChartResult,
  // redirectTo: Routes.ReportEditEnd,
  // },
];

export const constructionSiteReaderRoutesProps: RouteObject[] = [
  {
    path: Routes.Home,
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
  },

  // Report list validated / Manager Report history
  {
    path: Routes.ReportListValidated,
    element: (
      <ProtectedRoute>
        <ReportListValidatedPage />
      </ProtectedRoute>
    ),
  },
  // {
  // path: Routes.ManagerReportHistoryList,
  // redirectTo: Routes.ReportListValidated,
  // },

  // Task analyzer / tasks-analyzer old
  {
    path: Routes.TaskAnalyzer,
    element: (
      <ProtectedRoute>
        <TasksAnalyzer />
      </ProtectedRoute>
    ),
  },
  // {
  // path: Routes.TaskAnalyzerOld,
  // redirectTo: Routes.TaskAnalyzer,
  // },

  // Indicators analysis / indicators
  {
    path: Routes.Indicators,
    element: (
      <ProtectedRoute>
        <Indicators />
      </ProtectedRoute>
    ),
  },

  // Report export aggregated / Manager aggregated-report
  {
    path: Routes.ReportExportAggregated,
    element: (
      <ShiftProtectedRoute>
        <ReportExportAggregatedPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.ManagerAggregatedReport,
  // redirectTo: Routes.ReportExportAggregated,
  // },

  // Report view / Manager pdf report
  {
    path: Routes.ReportView,
    element: (
      <ShiftProtectedRoute>
        <ReportViewPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.ManagerReportPrint,
  // redirectTo: Routes.ReportView,
  // },

  // Report export batch / Manager batch report
  {
    path: Routes.ReportExportBatch,
    element: (
      <ShiftProtectedRoute>
        <ReportExportBatchPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.ManagerBatchReport,
  // redirectTo: Routes.ReportExportBatch,
  // },

  // Report aggregated / Manager pdf aggregated
  {
    path: Routes.ReportAggregated,
    element: (
      <ShiftProtectedRoute>
        <ReportAggregatedPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.ManagerAggregatedReportPrint,
  // redirectTo: Routes.ReportAggregated,
  // },

  // Task Export / Manager task-export
  {
    path: Routes.TaskExport,
    element: (
      <ShiftProtectedRoute>
        <TaskExportPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.TaskExportOld,
  // redirectTo: Routes.TaskExport,
  // },
];

export const dataManagerRoutesProps: RouteObject[] = [
  {
    path: Routes.Home,
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
  },

  // Report view / Manager pdf report
  {
    path: Routes.ReportView,
    element: (
      <ShiftProtectedRoute>
        <ReportViewPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.ManagerReportPrint,
  // redirectTo: Routes.ReportView,
  // },

  // Report aggregated / Manager pdf aggregated
  {
    path: Routes.ReportAggregated,
    element: (
      <ShiftProtectedRoute>
        <ReportAggregatedPage />
      </ShiftProtectedRoute>
    ),
  },
  // {
  // path: Routes.ManagerAggregatedReportPrint,
  // redirectTo: Routes.ReportAggregated,
  // },
];
