import dayjs from 'dayjs';
import React, { createContext, useContext, useMemo } from 'react';

import {
  CommonUserFieldsFragment,
  ConstructionSiteFieldsFragment,
} from 'generated/graphql';
import logger from 'technical/logger';
import timezoneManagement from 'technical/time-utils/dayjs.timezone';

import useConstructionSiteData from './user/provider/constructionSite';
import useUserData from './user/provider/user';
import { getAllowedRoles } from './user/services/authentication';
import { ROLES } from './user/services/config';
import { userHasRole, userIsDataManager } from './user/services/user';

export interface AppContext {
  user: CommonUserFieldsFragment | undefined;
  isDataManager: boolean;
  isConstructionSiteAdmin: boolean;
  isConstructionSiteManager: boolean;
  isConstructionSiteFullReader: boolean;
  isConstructionSiteReader: boolean;
  isConstructionSiteUser: boolean;
  hasManagerReadPermission: boolean;
  hasManagerEditPermission: boolean;
  constructionSites: ConstructionSiteFieldsFragment[];
  currentConstructionSiteId: string | undefined;
  currentConstructionSite: ConstructionSiteFieldsFragment | null;
  setCurrentConstructionSiteId: (constructionSiteId: string) => void;
  isConnected: boolean;
  appBootstraped: boolean;
  requestRebootstrap: () => Promise<void>;
}

const AppContext = createContext<AppContext>({
  user: undefined,
  isDataManager: false,
  isConstructionSiteAdmin: false,
  isConstructionSiteManager: false,
  isConstructionSiteFullReader: false,
  isConstructionSiteReader: false,
  isConstructionSiteUser: false,
  hasManagerReadPermission: false,
  hasManagerEditPermission: false,
  constructionSites: [],
  currentConstructionSiteId: undefined,
  currentConstructionSite: null,
  setCurrentConstructionSiteId: () => undefined,
  isConnected: false,
  appBootstraped: false,
  requestRebootstrap: () => Promise.resolve(),
});

interface Props {
  children: React.ReactNode;
}
export function AppProvider({ children }: Props) {
  const { isBootstraped: userIsBootstraped, ...userData } = useUserData();
  const {
    isBootstraped: constructionSiteIsBootstraped,
    ...constructionSiteData
  } = useConstructionSiteData(userData?.user);

  const userAllowedRoles = getAllowedRoles();

  const userRoles = useMemo(
    () => ({
      isDataManager: userIsDataManager(userAllowedRoles),
      isConstructionSiteAdmin: userHasRole(
        ROLES.CONSTRUCTION_SITE_ADMIN,
        constructionSiteData.currentConstructionSite,
        userData.user,
      ),
      isConstructionSiteManager: userHasRole(
        ROLES.CONSTRUCTION_SITE_MANAGER,
        constructionSiteData.currentConstructionSite,
        userData.user,
      ),
      isConstructionSiteFullReader: userHasRole(
        ROLES.CONSTRUCTION_SITE_FULL_READER,
        constructionSiteData.currentConstructionSite,
        userData.user,
      ),
      isConstructionSiteReader: userHasRole(
        ROLES.CONSTRUCTION_SITE_READER,
        constructionSiteData.currentConstructionSite,
        userData.user,
      ),
      isConstructionSiteUser: userHasRole(
        ROLES.USER,
        constructionSiteData.currentConstructionSite,
        userData.user,
      ),
    }),
    [userAllowedRoles, constructionSiteData, userData],
  );

  const userComputedPermissions = useMemo(
    () => ({
      hasManagerReadPermission:
        userRoles.isConstructionSiteManager ||
        userRoles.isConstructionSiteFullReader,
      hasManagerEditPermission: userRoles.isConstructionSiteManager,
    }),
    [userRoles],
  );

  const contextValue = useMemo(() => {
    return {
      ...userData,
      ...userRoles,
      ...userComputedPermissions,
      ...constructionSiteData,
      appBootstraped: userData.user
        ? userIsBootstraped && constructionSiteIsBootstraped
        : userIsBootstraped,
    };
  }, [
    userData,
    userRoles,
    userComputedPermissions,
    constructionSiteData,
    constructionSiteIsBootstraped,
    userIsBootstraped,
  ]);

  logger.info(
    'AppProvider',
    userData,
    userRoles,
    userComputedPermissions,
    constructionSiteData,
    // Get current default timezone info
    timezoneManagement.getDefaultTz(),
    dayjs.locale(),
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
