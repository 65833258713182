import { ApolloClient } from '@apollo/client';

import {
  getAuthEmail,
  getAuthId,
  getAuthResult,
} from 'business/user/services/authentication';
import {
  CommonUserFieldsFragment,
  ConstructionSiteFieldsFragment,
} from 'generated/graphql';

import { ROLES } from './config';
import {
  MUTATION_CREATE_MY_USER,
  MUTATION_UPDATE_USER_AUTHID,
  QUERY_MY_USER,
} from './query.gql';

export const userHasRole = (
  role: ROLES,
  constructionSite: ConstructionSiteFieldsFragment | null,
  user?: CommonUserFieldsFragment,
) => {
  if (!user || !constructionSite) {
    return false;
  }
  return constructionSite.userPermissions.some(
    (permission) =>
      permission.userId === user.id && permission.permissionType.name === role,
  );
};

export const userIsDataManager = (allowedRoles: ROLES[]) =>
  allowedRoles.includes(ROLES.DATA_MANAGER);

async function fetchUser(client: ApolloClient<object>) {
  const authID = getAuthId();
  const email = getAuthEmail();

  // try to get my user
  const data = await client.query({
    query: QUERY_MY_USER,
    variables: { id: authID, email },
    fetchPolicy: 'network-only',
  });

  if (data) {
    const {
      data: {
        user: [finalUser],
      },
    } = data;
    return finalUser;
  }

  return undefined;
}

async function createUser(client: ApolloClient<object>) {
  const authResult = getAuthResult();
  if (!authResult) {
    throw new Error('missing authResult to create new User');
  }
  const { email, sub } = authResult.idTokenPayload;

  // Create user in db
  const { data } = await client.mutate({
    mutation: MUTATION_CREATE_MY_USER,
    variables: {
      email,
      id: sub,
    },
  });

  if (!data) {
    throw new Error('no data after user creation');
  }

  const {
    insert_user: {
      returning: [freshlyCreatedMyUser],
    },
  } = data;

  return freshlyCreatedMyUser;
}

// Used to update user in order to add Auth0 id for admin created user.
// An update in `_set` is required to trigger the hasura preset who update authId
async function updateUserAuthId(client: ApolloClient<object>, id: string) {
  const authResult = getAuthResult();
  if (!authResult) {
    throw new Error('missing authResult to create new User');
  }

  const { email } = authResult.idTokenPayload;

  const { data } = await client.mutate({
    mutation: MUTATION_UPDATE_USER_AUTHID,
    variables: {
      id,
      email,
    },
  });

  if (!data) {
    throw new Error('no data after user creation');
  }

  const {
    update_user: {
      returning: [freshlyUpdatedUser],
    },
  } = data;

  return freshlyUpdatedUser;
}

export default async function getUserAndCreateIfNeeded(
  client: ApolloClient<object>,
) {
  let user = await fetchUser(client);

  if (!user) {
    // No user found
    user = await createUser(client);
  } else if (!user.authId) {
    // User created in BO doesn't have a authId before user first connects
    user = await updateUserAuthId(client, user.id);
  }

  return { ...user };
}
