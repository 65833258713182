import { gql } from '@apollo/client';

const ConstructionSiteFieldsFragment = gql`
  fragment ConstructionSiteFields on constructionSite {
    id
    name
    logo
    managerValidationRequired
    isManagerRequiredInReport
    isEngineerRequiredInReport
    nbDaysPerWeek
    timeZone
    isCompleted
    isAutofillEnabled
    aggregatedReportActivityLevel
    userPermissions {
      id
      userId
      permissionType {
        id
        name
      }
    }
  }
`;

const CommonUserFieldsFragment = gql`
  fragment CommonUserFields on user {
    id
    authId
    firstName
    lastName
    email
    isActive
    createdAt
    updatedAt
  }
`;

export const QUERY_MY_USER = gql`
  query QueryMyUser($id: String, $email: String) {
    user(
      where: {
        _or: [
          { authId: { _eq: $id } }
          { _and: [{ email: { _eq: $email } }, { authId: { _is_null: true } }] }
        ]
      }
    ) {
      ...CommonUserFields
    }
  }
  ${CommonUserFieldsFragment}
`;

export const QUERY_CONSTRUCTION_SITE_LIST = gql`
  query ConstructionSiteList {
    constructionSiteList: constructionSite {
      ...ConstructionSiteFields
    }
  }
  ${ConstructionSiteFieldsFragment}
`;

export const MUTATION_CREATE_MY_USER = gql`
  mutation CreateMyUser($id: String, $email: String) {
    insert_user(objects: [{ authId: $id, email: $email }]) {
      returning {
        ...CommonUserFields
      }
    }
  }
  ${CommonUserFieldsFragment}
`;

export const MUTATION_UPDATE_USER_AUTHID = gql`
  mutation UpdateUserAuthId($id: uuid, $email: String) {
    # Used to update user in order to add Auth0 id for admin created user.
    # An update in '_set' is required to trigger the hasura preset who update authId
    update_user(_set: { email: $email }, where: { id: { _eq: $id } }) {
      returning {
        ...CommonUserFields
      }
      affected_rows
    }
  }
  ${CommonUserFieldsFragment}
`;
