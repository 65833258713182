import { Auth0DecodedHash, WebAuth } from 'auth0-js';

import config from 'config/index';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';

import { DEFAULT_ALLOWED_ROLES, ROLES, ROLES_RANKING } from './config';

// TODO: use use-auth-react https://github.com/Swizec/useAuth
export const webAuth = new WebAuth({
  ...config.auth0,
  responseType: 'token id_token',
  scope: 'openid profile email',
});

// Auth0 Authorization object containing accessToken. Do not put in localStorage
let authResult: Auth0DecodedHash | null = null;
let currentAllowedRoles: ROLES[] = DEFAULT_ALLOWED_ROLES;

export function getAuthResult() {
  return authResult;
}

export function getAccessToken() {
  return authResult?.accessToken;
}

export function getAuthId() {
  return authResult?.idTokenPayload.sub;
}

export function getAuthEmail() {
  return authResult?.idTokenPayload.email;
}

export function isAuthenticated() {
  return !!authResult;
}

export function getAllowedRoles(): ROLES[] {
  return (
    authResult?.idTokenPayload['https://hasura.io/jwt/claims'][
      'x-hasura-allowed-roles'
    ] || []
  );
}

export function setCurrentAllowedRoles(roles: ROLES[]) {
  currentAllowedRoles = roles;
}

export function setDefaultCurrentAllowedRoles() {
  currentAllowedRoles = DEFAULT_ALLOWED_ROLES;
}

export function getHighestRole(roles: ROLES[] = currentAllowedRoles): ROLES {
  const allAllowedRoles = getAllowedRoles();
  if (allAllowedRoles.includes(ROLES.DATA_MANAGER)) {
    return ROLES.DATA_MANAGER;
  }
  const intersect = ROLES_RANKING.filter((role) => roles.includes(role));
  return intersect[0];
}

// Typed as ay as per Auth0 types
async function persistAuth(newAuthResult: Auth0DecodedHash) {
  authResult = newAuthResult;
}

function unpersistAuth() {
  authResult = null;
}

export function logout() {
  unpersistAuth();
  errorReporting.removeUser();
  // auth0 removes its cookies for silent reconnect and redirect to configured page = /
  webAuth.logout({ returnTo: import.meta.env.VITE_FRONT_URL });
}

export function login() {
  webAuth.authorize({
    mode: 'login',
    // if prompt === 'login', force reprompt, else, try to silently reconnect
    prompt: 'login',
  });
}

export const signUp = () => {
  webAuth.authorize({
    mode: 'signUp',
    // if prompt === 'login', force reprompt, else, try to silently reconnect
    prompt: 'login',
  });
};

export function renewToken(): Promise<any> {
  return new Promise((resolve, reject) => {
    webAuth.checkSession(
      {},
      function onCheckSession(auth0error, newAuthResult) {
        if (auth0error) {
          return reject(auth0error);
        }

        persistAuth(newAuthResult);
        return resolve(newAuthResult);
      },
    );
  });
}

export function requestLoginCallback() {
  return new Promise<void>((resolve, reject) =>
    webAuth.parseHash((err, newAuthResult) => {
      if (err) {
        // error.errorDescription contains an error key
        // - invalid-email-domain
        // - email-not-verified
        // Or a generic error message
        logger.error(err);
        errorReporting.error(new Error('auth0 parse hash error'), {
          auth0error: err.error,
        });
        return reject(err);
      }

      if (newAuthResult?.accessToken && newAuthResult.idToken) {
        logger.info('newAuthResult', newAuthResult);
        persistAuth(newAuthResult);

        return resolve();
      }

      return reject(new Error('An error occured during authentication'));
    }),
  );
}

export const isAuthInitialized = new Promise<void>((resolve) =>
  renewToken()
    .catch((err) => {
      if (err.code !== 'login_required' && err.code !== 'email-not-verified') {
        errorReporting.error(err);
      }
    })
    .finally(resolve),
);
