import { TFunction as reactI18NextTFunction, TOptions } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import { useTranslation as useReactI18NextTranslation } from 'react-i18next';

import logger from 'technical/logger';

import { changeLocale, getAntLocaleConfig } from './locale';

function tFactory(originalTFunction: reactI18NextTFunction) {
  return function t(key: string, options?: TOptions) {
    const result = originalTFunction(key, options);
    if (result === null) {
      logger.warn(`[Use Translation] Unknown key ${key}`);
      return key;
    }
    return result;
  };
}

export function useTranslation() {
  const { t: reactI18NextT, i18n } = useReactI18NextTranslation();

  function changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
    changeLocale(getAntLocaleConfig(lang).locale);
  }

  return {
    t: tFactory(reactI18NextT),
    language: i18n.language,
    changeLanguage,
  };
}

export type TFunction = ReturnType<typeof tFactory>;
