import antdEnGB from 'antd/lib/locale/en_GB';
import antdEnUS from 'antd/lib/locale/en_US';
import antdEsES from 'antd/lib/locale/es_ES';
import antdFrFR from 'antd/lib/locale/fr_FR';
import dayjs from 'dayjs';

export function changeLocale(name: string) {
  dayjs.locale(name);
}

export function getAntLocaleConfig(language: string) {
  if (language === 'fr') {
    return antdFrFR;
  }
  if (language === 'en-US') {
    return antdEnUS;
  }
  if (language === 'es') {
    return antdEsES;
  }
  return antdEnGB;
}
