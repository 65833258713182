import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import {
  getAccessToken,
  getHighestRole,
  renewToken,
} from 'business/user/services/authentication';
import config from 'config/index';
import logger from 'technical/logger';

const authLink = setContext((_, { headers: originalHeader }: any) => {
  const headers = {
    ...originalHeader,
  };

  // Get the authentication token from auth service if it exists
  const token = getAccessToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  headers['x-hasura-role'] = getHighestRole();

  // return the headers to the context so httpLink can read them
  return {
    headers,
  };
});

const httpLink = new HttpLink({
  uri: `${config.hasura.graphqlUri}/v1/graphql`,
  // Custom fetch to handle reconnection on jwt expired
  fetch: (input, init) => {
    return fetch(input, init).then(async (response) => {
      const json = await response.json();
      if (json.errors && json.errors[0]?.extensions?.code === 'invalid-jwt') {
        logger.info('Renewing token');
        return renewToken().then((authResult) => {
          const newToken = authResult.accessToken;
          logger.info('Token renewed!');
          // Updating headers with new token
          return fetch(input, {
            ...init,
            headers: {
              ...init?.headers,
              Authorization: `Bearer ${newToken}`,
            },
          });
        });
      }

      // Recreating json ad text method that ca be called only one beefore forwardig
      return {
        ...response,
        json: () => Promise.resolve(json),
        text: () => Promise.resolve(JSON.stringify(json)),
      };
    });
  },
});

/**
 * Disabled the websocket link, not using for now
 */

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;
