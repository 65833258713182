import { Result } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { i18n } from 'translations';

import ConnectedPage from 'business/layout/connectedPage';
import DisconnectedPage from 'business/layout/disconnectedPage';
import { useAppContext } from 'business/provider';
import { login } from 'business/user/services/authentication';
import errorReporting from 'technical/error-reporting';
import Button from 'ui/button';

export function NotFound() {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle={i18n.t('user.notFound')}
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          {i18n.t('common.go_back')}
        </Button>
      }
    />
  );
}

function ReportingNoMatch() {
  const { isConnected } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    if (!isConnected) {
      login();
    }
    errorReporting.info(new Error('404'), { location: location.pathname });
  }, [isConnected, location.pathname]);

  const Page = isConnected ? ConnectedPage : DisconnectedPage;

  return (
    <Page>
      <NotFound />
    </Page>
  );
}

export default ReportingNoMatch;
