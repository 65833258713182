import { ApolloProvider } from '@apollo/client';
import { ConfigProvider, Result } from 'antd';
import { useTranslation } from 'translations/hooks';
import { changeLocale, getAntLocaleConfig } from 'translations/locale';

import defaultTheme from 'config/theme';
import ErrorBoundary from 'technical/error-boundary';
import apolloClient from 'technical/graphql/client';

import './App.scss';
import './main.scss';
import AppBootstrapper from './AppBootstrapper';
import { AppProvider } from './provider';
import Router from './router';

const App = () => {
  const { language, t } = useTranslation();
  const antdLocale = getAntLocaleConfig(language);

  changeLocale(antdLocale.locale);

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <AppProvider>
          <AppBootstrapper>
            <div className="other-screen">
              <ConfigProvider locale={antdLocale} theme={defaultTheme}>
                <Router />
              </ConfigProvider>
            </div>
            <div className="mobile-screen">
              <Result status="warning" title={t('errors.no_mobile_support')} />
            </div>
          </AppBootstrapper>
        </AppProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
export default App;
