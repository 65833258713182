import {
  CloseOutlined,
  PrinterOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Descriptions,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { ReactNode, useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { ILanguage, i18n } from 'translations';
import { useTranslation, TFunction } from 'translations/hooks';

import { ACTIVITY_TYPES } from 'business/activity/types';
import { useAppContext } from 'business/provider';
import ReportChart from 'business/report/components/ReportChart';
import ShiftProductionGraph from 'business/report/components/ShiftProductionGraph';
import {
  formatDurationToReadable,
  formatReportDateStringToLocalizedDateStringWithDayOfWeek,
} from 'business/report/services/timeOperations';
import { parseTaskDateString } from 'business/task/services/timeOperations';
import config from 'config';
import Routes from 'config/routes';
import {
  useGetIndicatorResultsQuery,
  useGetPreviousPrintReportQuery,
  useGetPreviousPrintTasksQuery,
  useGetShiftReportPdfMetadataQuery,
  useInvalidateManagerValidationMutation,
} from 'generated/graphql';
import logger from 'technical/logger';
import { useMediaType } from 'technical/media/hooks';
import { NotFound } from 'technical/router/switch/reporting-no-match';
import AppLogo from 'ui/appLogo';
import Loader from 'ui/loader';

import DisplayFiles from './displayTasksFiles';
import './index.scss';
import {
  averageRingTimeWithTypeSelection,
  getFinishedRingFromTaskList,
  getTasksByCategory,
  reportIsValidated,
  tasksHasFiles,
} from './services';

const { Paragraph } = Typography;

const getTableColumnsWithDescription = (t: TFunction) => [
  {
    title: t('pages.print.table.startTime'),
    dataIndex: 'startTime',
  },
  {
    title: t('pages.print.table.endTime'),
    dataIndex: 'endTime',
  },
  {
    title: t('pages.print.table.spentTime'),
    dataIndex: 'spentTime',
    render: (node: number) => formatDurationToReadable(node),
  },
  {
    title: t('pages.print.table.ring'),
    dataIndex: 'ring',
  },
  {
    title: t('pages.print.table.critical'),
    dataIndex: 'critical',
    render: (check: boolean) => (check ? <CloseOutlined /> : null),
  },
  {
    title: t('pages.print.table.description'),
    dataIndex: 'description',
  },
  {
    title: t('pages.print.table.note'),
    dataIndex: 'note',
  },
];

function ReportViewPage() {
  const { t } = useTranslation();
  const {
    currentConstructionSite,
    constructionSites,
    hasManagerEditPermission,
  } = useAppContext();
  const navigate = useNavigate();
  const { id: shiftReportId } = useParams<{ id: string }>();
  invariant(shiftReportId, "id isn't set within the route");

  let totalTasksTime = dayjs.duration(0, 'minutes');
  let totalExcavationTime = dayjs.duration(0, 'minute');
  let totalBuildTime = dayjs.duration(0, 'minute');
  let totalCriticalTasksTime = dayjs.duration(0, 'minute');
  let totalCriticalExcavationTime = dayjs.duration(0, 'minute');
  let totalCriticalBuildTime = dayjs.duration(0, 'minute');
  const { isTablet } = useMediaType();

  const { loading, error, data } = useGetShiftReportPdfMetadataQuery({
    variables: {
      shiftReportId,
      constructionSiteId: currentConstructionSite?.id,
    },
    skip: !shiftReportId || !currentConstructionSite?.id,
    fetchPolicy: 'cache-and-network',
  });
  const [invalidateManagerValidation] =
    useInvalidateManagerValidationMutation();

  useEffect(
    function alertUser() {
      if (error) {
        message.error(t('errors.error_generic'));
        logger.error(error);
      }
    },
    [error, t],
  );

  const report = data?.shiftReport ? data.shiftReport : undefined;
  const tasksForAverageExcavation = data?.tasksForAverageExcavation
    ? data.tasksForAverageExcavation
    : undefined;
  const tasksForAverageBuild = data?.tasksForAverageBuild
    ? data.tasksForAverageBuild
    : undefined;
  const endedExcavationRings = getFinishedRingFromTaskList(
    tasksForAverageExcavation,
  );
  const endedBuildRings = getFinishedRingFromTaskList(tasksForAverageBuild);
  const nbRingsBuild = endedBuildRings.length;

  const reportName = report?.constructionSite.name;
  const logo = report?.constructionSite.logo;
  const nbTasks = report?.tasks.length ?? 0;
  const startRing = report?.tasks[0]?.ring ?? 0;
  const endRing = report?.tasks[nbTasks - 1]?.ring ?? 0;

  const {
    loading: loadingPreviousReport,
    error: errorPreviousReport,
    data: dataPreviousReport,
  } = useGetPreviousPrintReportQuery({
    variables: {
      constructionSiteId: report?.constructionSite?.id,
      date: report?.date,
      shiftReportId: report?.id,
      shiftStartTime: report?.shift.startTime,
    },
    skip: !report,
  });

  const previousReport = dataPreviousReport?.shiftReport
    ? dataPreviousReport.shiftReport[0]
    : undefined;

  const {
    loading: loadingPreviousTasks,
    error: errorPreviousTasks,
    data: dataPreviousTasks,
  } = useGetPreviousPrintTasksQuery({
    variables: {
      constructionSiteId: report?.constructionSite?.id,
      shiftReportId,
      firstEndedExcavationRing: endedExcavationRings[0],
      firstEndedBuildRing: endedBuildRings[0],
      withExcavationTasks: !!endedExcavationRings[0],
      withBuildTasks: !!endedBuildRings[0],
    },
    skip: !report,
  });

  const {
    loading: loadingIndicatorResults,
    error: errorIndicatorResults,
    data: dataIndicatorResults,
  } = useGetIndicatorResultsQuery({
    variables: {
      shiftReportId,
      constructionSiteId: report?.constructionSite?.id,
    },
    skip: !report,
    fetchPolicy: 'network-only',
  });

  useEffect(
    function alertUser() {
      if (errorPreviousReport) {
        message.error(t('errors.error_generic'));
        logger.error(errorPreviousReport);
      }
      if (errorPreviousTasks) {
        message.error(t('errors.error_generic'));
        logger.error(errorPreviousTasks);
      }
      if (errorIndicatorResults) {
        message.error(t('errors.error_generic'));
        logger.error(errorIndicatorResults);
      }
    },
    [errorPreviousReport, errorPreviousTasks, errorIndicatorResults, t],
  );

  if (report) {
    totalTasksTime = totalTasksTime.add(
      report.tasks.reduce((acc, task) => {
        return (
          acc +
          (task.duration ||
            parseTaskDateString(task.endDate).diff(
              parseTaskDateString(task.startDate),
              'minutes',
            ))
        );
      }, 0),
      'minutes',
    );
    report.tasks.forEach((task) => {
      if (task.activity.type === 'excavation') {
        totalExcavationTime = totalExcavationTime.add(task.duration!, 'minute');
      }
      if (task.activity.type === 'build') {
        totalBuildTime = totalBuildTime.add(task.duration!, 'minute');
      }
      if (task.critical) {
        totalCriticalTasksTime = totalCriticalTasksTime.add(
          task.duration!,
          'minute',
        );
        if (task.activity.type === 'excavation') {
          totalCriticalExcavationTime = totalCriticalExcavationTime.add(
            task.duration!,
            'minute',
          );
        }
        if (task.activity.type === 'build') {
          totalCriticalBuildTime = totalCriticalBuildTime.add(
            task.duration!,
            'minute',
          );
        }
      }
    });
  }

  const handlePrint = () => {
    window.print();
  };

  const handleInvalidateManagerValidation = async () => {
    const validationOptions = currentConstructionSite?.managerValidationRequired
      ? {
          managerValidation: false,
          operatorValidation: true,
        }
      : {
          managerValidation: false,
          operatorValidation: false,
        };

    try {
      const { errors: invalidateErrors } = await invalidateManagerValidation({
        variables: { id: shiftReportId, ...validationOptions },
      });
      if (invalidateErrors) {
        message.error(t('pages.print.invalidateShiftReportFailed'));
        logger.error(invalidateErrors);
      } else {
        message.success(t('pages.print.invalidateShiftReportSuccess'));
        navigate(generatePath(Routes.ReportEdit, { id: shiftReportId }));
      }
    } catch (err) {
      message.error(t('pages.print.invalidateShiftReportFailed'));
      logger.error(err);
    }
  };

  if (
    !loading &&
    !report?.constructionSite &&
    !constructionSites.find(({ id }) => id === report?.constructionSite.id)
  ) {
    return <NotFound />;
  }

  const averageRingTimePerType = averageRingTimeWithTypeSelection(
    endedExcavationRings,
    endedBuildRings,
    tasksForAverageExcavation,
    tasksForAverageBuild,
    dataPreviousTasks,
  );

  const averageExcavationPerRing = averageRingTimePerType(
    ACTIVITY_TYPES.EXCAVATION,
  );
  const averageBuildPerRing = averageRingTimePerType(ACTIVITY_TYPES.BUILD);
  const indicators = dataIndicatorResults?.calculateIndicatorResults?.data;

  return (
    <div className="report-view-page page-appear">
      {(loading ||
        loadingPreviousReport ||
        loadingPreviousTasks ||
        loadingIndicatorResults) && <Loader />}
      {data && dataPreviousReport && report && (
        <>
          {!report.operatorValidation && (
            <Alert
              className="alert"
              message={t('pages.print.inProgressReport')}
              banner
              type="warning"
            />
          )}
          <div className="controls">
            <Button
              type="primary"
              ghost
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('common.go_back')}
            </Button>
            <Space>
              {reportIsValidated(report, currentConstructionSite) &&
                hasManagerEditPermission &&
                !currentConstructionSite?.isCompleted && (
                  <Popconfirm
                    title={t('pages.print.invalidateManagerValidationHelper')}
                    onConfirm={handleInvalidateManagerValidation}
                  >
                    <Button icon={<RollbackOutlined />}>
                      {t('pages.print.invalidateManagerValidationButton')}
                    </Button>
                  </Popconfirm>
                )}
              {reportIsValidated(report, currentConstructionSite) &&
                !isTablet && (
                  <Button
                    onClick={handlePrint}
                    icon={<PrinterOutlined />}
                    type="primary"
                  >
                    {t('common.print')}
                  </Button>
                )}
            </Space>
          </div>
          <div className="header">
            <div className="header-left">
              <AppLogo />
              {logo && (
                <AppLogo
                  alt="Construction site logo"
                  path={`${config.gcp.publicUri}/${logo}`}
                />
              )}
            </div>
            <h1>{t('pages.print.mainTitle', { reportName })}</h1>
          </div>

          {/* Date */}
          <Descriptions
            layout="horizontal"
            size="small"
            className="header-info"
            column={4}
          >
            <Descriptions.Item label={t('pages.form.date.label')} span={1}>
              {formatReportDateStringToLocalizedDateStringWithDayOfWeek(
                report.date,
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.form.shift.label')} span={1}>
              {report.shift.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.form.creator.label')} span={2}>
              {report.userName}
            </Descriptions.Item>
          </Descriptions>

          {/* Team */}
          <Descriptions
            layout="horizontal"
            size="small"
            className="header-info"
            column={2}
          >
            <Descriptions.Item label={t('pages.form.operator.label')}>
              {report.operator?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.form.shiftLeader.label')}>
              {report.shiftLeader?.name}
            </Descriptions.Item>
            {report.shiftEngineer && (
              <Descriptions.Item label={t('pages.form.shiftEngineer.label')}>
                {`${report.shiftEngineer.firstName} ${report.shiftEngineer.lastName}`}
              </Descriptions.Item>
            )}
            {report.shiftManager && (
              <Descriptions.Item label={t('pages.form.shiftManager.label')}>
                {`${report.shiftManager.firstName} ${report.shiftManager.lastName}`}
              </Descriptions.Item>
            )}
          </Descriptions>

          {/* build & excavation average */}
          {previousReport?.lastFinishedRing != null ? (
            <Descriptions
              layout="horizontal"
              size="small"
              className="header-info"
              column={2}
            >
              <Descriptions.Item label={t('pages.print.excavationPerRing')}>
                {t('pages.print.ringPerMinute', {
                  averageTime: averageExcavationPerRing,
                  context: averageExcavationPerRing ? '' : 'unavailable',
                })}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.print.buildPerRing')}>
                {t('pages.print.ringPerMinute', {
                  averageTime: averageBuildPerRing,
                  context: averageBuildPerRing ? '' : 'unavailable',
                })}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <Descriptions
              layout="horizontal"
              size="small"
              className="header-info"
              column={2}
            >
              <Descriptions.Item label={t('pages.print.excavationPerRing')}>
                {t('pages.print.missingPreviousReport')}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.print.buildPerRing')}>
                {t('pages.print.missingPreviousReport')}
              </Descriptions.Item>
            </Descriptions>
          )}

          {/* total availability */}
          <Descriptions
            layout="horizontal"
            size="small"
            className="header-info"
            column={4}
          >
            <Descriptions.Item span={2} label={t('pages.print.totalTime')}>
              {formatDurationToReadable(totalTasksTime.asMinutes())}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={t('pages.print.totalCriticalTime')}
            >
              {formatDurationToReadable(totalCriticalTasksTime.asMinutes())}
            </Descriptions.Item>
            {indicators && indicators.length > 0
              ? indicators.map((indicator) => {
                  if (indicator) {
                    return (
                      <Descriptions.Item
                        key={indicator.id}
                        span={1}
                        label={
                          {
                            [ILanguage.fr]: indicator.nameFr,
                            [ILanguage.enGB]: indicator.nameEn,
                            [ILanguage.enUS]: indicator.nameEn,
                            [ILanguage.es]: indicator.nameEs,
                          }[i18n.language] ?? indicator.nameEn
                        }
                      >
                        {indicator.value != null
                          ? +indicator.value.toFixed(2)
                          : '- '}
                        {indicator.unit}
                      </Descriptions.Item>
                    );
                  }
                  return undefined;
                })
              : null}
          </Descriptions>

          <div className="bloc with-page-break" key="chart">
            <h1>
              <span>
                {t('pages.print.ringTitle', {
                  startRing,
                  endRing,
                  count: nbRingsBuild,
                  context: nbRingsBuild > 0 ? 'withTotal' : undefined,
                })}
              </span>
              <span>
                {t('pages.print.distanceTitle', {
                  startMetricPoint: report.startMetricPoint,
                  endMetricPoint: report.endMetricPoint,
                  distanceUnit: report.constructionSite.distanceUnit,
                  context:
                    !report.operatorValidation || !report.endMetricPoint
                      ? 'inProgress'
                      : null,
                })}
              </span>
            </h1>
            <ReportChart
              shift={report.shift}
              date={report.date}
              activities={data.activities}
              tasks={report.tasks}
              criticalPathValid={false}
              mode="view"
            />
          </div>
          {/* TODO: we should be able to use this + the associated query */}
          {nbTasks > 0 && (
            <div className="bloc" key="tasks-tables">
              <h1>{t('pages.print.activityNotes')}</h1>
              {data.activities.reduce((tableList, activity) => {
                const tableData = getTasksByCategory(activity.id, report.tasks);
                if (!tableData) {
                  return tableList;
                }
                return [
                  tableList,
                  <Table
                    key={activity.name}
                    rowKey="key"
                    columns={getTableColumnsWithDescription(t)}
                    dataSource={tableData}
                    bordered
                    pagination={false}
                    title={() => {
                      return (
                        <h3 className="activity-header">{activity.name}</h3>
                      );
                    }}
                    size="small"
                  />,
                ];
              }, [] as ReactNode[])}
            </div>
          )}
          {report?.note && (
            <div className="bloc" key="tasks-note">
              <h1>{t('pages.print.reportNote')}</h1>
              <Paragraph>{report?.note || 'N/A'}</Paragraph>
            </div>
          )}
          <div className="bloc" key="critical-tasks-pie-chart">
            <h1>
              {t('pages.print.shiftProductionTime', { context: 'critical' })}
            </h1>
            <ShiftProductionGraph reportId={report.id} critical />
          </div>
          {tasksHasFiles(report.tasks) && (
            <div className="bloc" key="additional-files">
              <h1>{t('pages.print.additionnalImages')}</h1>
              <DisplayFiles tasks={report.tasks} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ReportViewPage;
