import { HistoryOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'translations/hooks';

import { useAppContext } from 'business/provider';
import NewReportForm from 'business/report/components/NewReportForm';
import ReportCard from 'business/report/components/ReportCard';
import MaintenanceAlert from 'business/user/components/maintenanceAlert';
import Routes from 'config/routes';
import { useGetPreviousReportQuery } from 'generated/graphql';
import Loader from 'ui/loader';
import './index.scss';

function ReportCreatePage() {
  const { t } = useTranslation();
  const { currentConstructionSite } = useAppContext();

  const { loading, error, data, refetch } = useGetPreviousReportQuery({
    variables: {
      constructionSiteId: currentConstructionSite?.id,
    },
    fetchPolicy: 'network-only',
    skip: !currentConstructionSite?.id,
  });

  useEffect(
    function alertUser() {
      if (error) {
        message.error(error.message);
      }
    },
    [error],
  );

  const lastReport =
    data?.shiftReport && data.shiftReport.length > 0
      ? data.shiftReport[0]
      : undefined;

  return (
    <div className="report-create-page main-bg-image page-appear">
      <MaintenanceAlert />
      {loading && <Loader />}
      {!loading && (
        <>
          <NewReportForm previousReport={lastReport} />
          {lastReport && (
            <ReportCard report={lastReport} refetchList={refetch} />
          )}

          <Link to={Routes.ReportList} className="button-center">
            <Button type="primary" icon={<HistoryOutlined />}>
              {t('pages.report.new.openReportHistory')}
            </Button>
          </Link>
        </>
      )}
    </div>
  );
}

export default ReportCreatePage;
