import { Outlet, RouteObject, createBrowserRouter } from 'react-router-dom';

import { useAppContext } from 'business/provider';
import ErrorBoundaryForRouter from 'technical/error-boundary/error-boundary-router';

import {
  commonRoutesProps,
  constructionSiteManagerEditRoutesProps,
  constructionSiteManagerReadRoutesProps,
  constructionSiteReaderRoutesProps,
  dataManagerRoutesProps,
  noConstructionSite,
  unauthorizedRoutesProps,
  userRoutesProps,
} from './routes';

const createBrowserRouterWithErrorCatcher = (routes: RouteObject[]) => {
  return createBrowserRouter([
    {
      element: <Outlet />,
      errorElement: <ErrorBoundaryForRouter />,
      children: routes,
    },
  ]);
};

export const useAppRouter = () => {
  const {
    isConstructionSiteManager,
    isConstructionSiteFullReader,
    isConstructionSiteReader,
    isDataManager,
    isConstructionSiteUser,
    constructionSites,
  } = useAppContext();

  if (constructionSites.length === 0) {
    return createBrowserRouterWithErrorCatcher([
      ...commonRoutesProps,
      ...noConstructionSite,
    ]);
  }

  /**
   * Order of the condition should be the same as src/business/user/services/config.ts
   * who define which role will be used for the request. The user should get the routes
   * corresponding to it's highest role.
   */
  if (isDataManager) {
    return createBrowserRouterWithErrorCatcher([
      ...commonRoutesProps,
      ...constructionSiteManagerReadRoutesProps,
      ...constructionSiteManagerEditRoutesProps,
      ...dataManagerRoutesProps,
    ]);
  }
  if (isConstructionSiteManager) {
    return createBrowserRouterWithErrorCatcher([
      ...commonRoutesProps,
      ...constructionSiteManagerReadRoutesProps,
      ...constructionSiteManagerEditRoutesProps,
    ]);
  }
  if (isConstructionSiteFullReader) {
    return createBrowserRouterWithErrorCatcher([
      ...constructionSiteManagerReadRoutesProps,
      ...commonRoutesProps,
    ]);
  }
  if (isConstructionSiteReader) {
    return createBrowserRouterWithErrorCatcher([
      ...constructionSiteReaderRoutesProps,
      ...commonRoutesProps,
    ]);
  }
  if (isConstructionSiteUser) {
    return createBrowserRouterWithErrorCatcher([
      ...userRoutesProps,
      ...commonRoutesProps,
    ]);
  }

  return createBrowserRouterWithErrorCatcher([
    ...commonRoutesProps,
    ...unauthorizedRoutesProps,
  ]);
};
